<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="设备编号">
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item label="时间范围">
			  <el-date-picker
			      value-format="yyyy-MM-dd"
			      v-model="date_value"
			      type="daterange"
			      range-separator="至"
			      start-placeholder="开始日期"
			      end-placeholder="结束日期">
			  </el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button @click="alarm_message()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-download" @click="alarm_export()" style="margin-right: 10px;">导出</el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column prop="deviceNo" label="设备编号" ></el-table-column>
			<el-table-column prop="ch4State" label="甲烷传感器状态" ></el-table-column>
			<el-table-column prop="ch4Concentration" label="甲烷浓度(单位%LEL)"></el-table-column>
			<el-table-column prop="coState" label="一氧化碳传感器状态" ></el-table-column>
			<el-table-column prop="coConcentration" label="一氧化碳浓度(单位%PPM)" ></el-table-column>
			<el-table-column prop="content" label="报警消息内容" ></el-table-column>
			<el-table-column prop="createTime" label="报警时间" ></el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination @current-change="changePage" @size-change="sizeChange" :page-sizes="[ 10 , 20 , 30 , 40]"
				:total="total" :currentPage="form.startPage" :pageSize="form.pageSize" background
				layout="total, sizes, prev, pager, next, jumper">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	var fileDownload = require('js-file-download');
	export default {
		data() {
			return {
				loading: false,
				form: {
					startPage: 1,
					pageSize: 10,
					deviceNo:'',
					// 可选	String	query	设备编号
					userId:'',
					// 可选	Integer	query	用户ID（小程序用户查询时必选）
					startDate:'',
					endDate:''
				},
				total: 0,
				list: [],
				date_value:''
			}
		},
		created() {
			this.alarm_message();
		},
		mounted() {

		},
		methods: {
			alarm_message() {
				this.loading = true;
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					this.form.startDate = this.date_value[0];
					this.form.endDate = this.date_value[1];
				}else{
					this.form.startDate = "";
					this.form.endDate = "";
				}
				this.$api.alarm_list(this.form).then(res => {
					if (res.code == 200) {
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					} else {
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			changePage(e) {
				this.form.startPage = e;
				this.alarm_message();
			},
			sizeChange(e) {
				this.form.pageSize = e;
				this.alarm_message();
			},
			alarm_export() {
				let data = {};
				if( this.date_value != "" && this.date_value != null && this.date_value != undefined ){
					data.startDate = this.date_value[0];
					data.endDate = this.date_value[1];
				}else{
					data.startDate = "";
					data.endDate = "";
				}
				data.deviceNo = this.form.deviceNo;
				data.userId = this.form.userId;
				this.$api.alarm_export( data ).then(res => {
					fileDownload(res, "报警消息列表.xls");
				});
			}
		}
	}
</script>